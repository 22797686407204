import Free_plan from "./../../assets/image/pages/pricing/free-plan.png";
import Basic_plan from "./../../assets/image/pages/pricing/basic-plan.png";
import Expert_plan from "./../../assets/image/pages/pricing/expert-plan.png";
import Business_plan from "./../../assets/image/pages/pricing/business-plan.png";

import { FreeChecked, BasicChecked, ProChecked, BusinessChecked } from "../../Icons";
import { BasicChecke2, ProChecke2 } from "../../Icons";
import { Empty, Information } from "../../Icons";

export const GetSelectedPlan = () => {
	let selectedPlan = localStorage.getItem('selected_plan');
	return selectedPlan;
}

export const SetSelectedPlan = (plan) => {
	localStorage.setItem('selected_plan', plan);
}

export const plandata_config = [
	[
		{
			class: "",
			icon_path: Free_plan,
			name: "Free",
			description: "Perfect for quick one off projects under 10 minutes",
			plan: 0,
			monthlyprice: 0,
			Annuallyprice: 0,
			price_description: "per editor/mont",
			btnTxt: "Sign Up For Free",
			featureList: {
				icon: <FreeChecked />,
				items: [
					"Watermarked",
					"10 minutes of subtitles",
					"Upload limit - 1GB",
					"Export length - 10 minutes"
				]
			},
			//footerTxt: "See All Features",

			modalInfo: {
				status: false
			}
		}, {
			class: "",
			icon_path: Basic_plan,
			name: "Basic",
			description: "Simple online video editing for individuals",
			plan: 1,
			monthlyprice: 20,
			Annuallyprice: 12,
			saveUp: 28,
			price_description: "per editor/month",
			btnTxt: "Go Basic",
			featureList: {
				icon: <BasicChecked />,
				items: [
					"Export Quality - 1080p",
					"No watermark",
					"720 minutes of subtitles",
					"Upload file size - unlimited",
					"Export length - 25 minutes"
				]
			},
			// footerTxt: "See All Features",

			modalInfo: {
				status: true,
				description: "For users who need simple online video editing",
				checkIcon: <BasicChecke2 />,
				planmin: 60,
				planIndex: 2,
				lists: [
					"Unlimited upload file size",
					"No watermarks",
					"Auto Subtitles - Up to ",
					"Export videos up to 25 minutes long",
					"20GB of storage",
					"Clean Audio",
					"Export videos in 1080p"
				]
			}
		}
	], [
		{
			class: "most-popular",
			icon_path: Expert_plan,
			name: "Expert",
			description: "Translations, Text to Speech and more for expert tools",
			plan: 2,
			monthlyprice: 30,
			Annuallyprice: 18,
			saveUp: 21,
			price_description: "per editor/month",
			btnTxt: "Go Pro",
			featureList: {
				icon: <ProChecked />,
				items: [
					"1,440 minutes of subtitles",
					"Translations 50+ languages",
					"Export length - 120 minutes",
					"AI Voiceover 100K+ chars",
				]
			},
			// footerTxt: "See All Features",
			modalInfo: {
				status: true,
				description:
					"For professionals that need translations, subtitle exports, longer videos and brand tools",
				checkIcon: <ProChecke2 />,
				planmin: 120,
				planIndex: 1,
				lists: [
					"Everything in BASIC, plus ...",
					"Subtitles : Up to ",
					"Subtitle translations",
					"Edit videos up to 2 hours long",
					"50GB of storage",
					"Clean Audio",
					"Text To Speech: Up to 72,000 characters",
					"Advanced export options"
				]
			}
		}, {
			class: "",
			icon_path: Business_plan,
			name: "Business",
			description: "Advanced collaboration, access and privacy",
			plan: 3,
			monthlyprice: 60,
			Annuallyprice: 46,
			saveUp: 16,
			price_description: "per editor/month",
			btnTxt: "Go Business",
			featureList: {
				icon: <BusinessChecked />,
				items: [
					"7200 minutes of subtitles",
					"100GB storage",
					"Custom templates",
					"Collaborative editing"
				]
			},
			// footerTxt: "See All Features",
			modalInfo: {
				status: true,
				description:
					"For teams with advanced collaboration, access, transcription and privacy needs",
				checkIcon: <ProChecke2 />,
				planIndex: 1,
				planmin: 600,
				lists: [
					"Everything in Expert, plus ...",
					"Subtitles : Up to ",
					"Custom templates",
					"Project storage of 100GB",
					"Add-on: Private workspaces",
				]
			}
		}
	]
];

const GridListClass = "compares-gridlist-icon";
export const comparePlan_lists = [
	// Basics sections
	[{ type: "headerTitle", title: "The Basics" }],
	[
		{
			type: "header",
			title: "No Watermark",
			tip: "Whether your video is watermark free",
			infoIcon: <Information />
		},
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <BasicChecked className={GridListClass} /> },
		{ type: "icon", item: <ProChecked className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	], [
		{
			type: "header",
			title: "Video Export Length",
			tip: "The maximum video length you can export",
			infoIcon: <Information />
		},
		{ type: "title", title: "10 mins" },
		{ type: "title", title: "25 mins" },
		{ type: "title", title: "2 hours" },
		{ type: "title", title: "2 hours" }
	], [
		{
			type: "header",
			title: "Upload File Size",
			tip: "The maximum file size that you can upload",
			infoIcon: <Information />
		},
		{ type: "title", title: "250MB" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Projects",
			tip: "Number of projects you can make",
			infoIcon: <Information />
		},
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Storage",
			tip: "Storage limits for your projects and their assets",
			infoIcon: <Information />
		},
		{ type: "title", title: "2GB" },
		{ type: "title", title: "10GB" },
		{ type: "title", title: "50GB" },
		{ type: "title", title: "100GB" }
	], [
		{
			type: "header",
			title: "Export Quality",
			tip: "Highest exportable project resolution",
			infoIcon: <Information />
		},
		{ type: "title", title: "720p" },
		{ type: "title", title: "1080p" },
		{ type: "title", title: "1080p" },
		{ type: "title", title: "4k" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Text Animations",
	// 		tip: "Add animated text to your videos",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Limited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Stock Audio",
	// 		tip: "Access to our vast library of stock audio",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Limited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Stock Video",
	// 		tip: "Access to our vast library of stock video",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Limited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Templates",
			tip: "Professional & editable video templates",
			infoIcon: <Information />
		},
		{ type: "title", title: "Limited" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Custom Templates",
			tip:
				"Create your own templates to make video creation faster and more consistent",
			infoIcon: <Information />
		},
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	], [
		{
			type: "header",
			title: "Audio Noise Removal",
			tip: "Remove background noise and enhance your audio quality",
			infoIcon: <Information />
		},
		{ type: "title", title: "Up to 5 min/mo" },
		{ type: "title", title: "Up to 200 min/yr" },
		{ type: "title", title: "Up to 600 min/yr" },
		{ type: "title", title: "Up to 1500 min/yr" }
	], [
		{
			type: "header",
			title: "Support",
			tip: "Help from our friendly support team",
			infoIcon: <Information />
		},
		{ type: "title", title: "Chat" },
		{ type: "title", title: "Chat" },
		{ type: "title", title: "Chat" },
		{ type: "title", title: "Custom support on request" }
	],
	// Subtitles section
	[{ type: "border" }],
	[{ type: "headerTitle", title: "Subtitles" }],
	[
		{
			type: "header",
			title: "Auto Subtitles",
			tip: "Maximum allowed transcription time in one billing period",
			infoIcon: <Information />
		},
		{ type: "title", title: "Up to 10 min/mo" },
		{ type: "title", title: "Up to 720 min/yr" },
		{ type: "title", title: "Up to 1,440 min/yr" },
		{ type: "title", title: "Up to 7,200 min/yr" }
	], [
	// 	{
	// 		type: "header",
	// 		title: "SRT Subtitles Downloads",
	// 		tip:
	// 			"Ability to export .srt, .vtt and .txt files with generated subtitles",
	// 		infoIcon: <Information />
	// 	},
	// 	{
	// 		type: "icon",
	// 		item: <Empty className={GridListClass} />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
		{
			type: "header",
			title: "Subtitle Translation",
			tip:
				"Translate subtitles into more than 50 languages - counts towards your subtitle transcription time",
			infoIcon: <Information />
		},
		{ type: "icon", item: <FreeChecked className={GridListClass} /> },
		{ type: "icon", item: <BasicChecked className={GridListClass} /> },
		{ type: "icon", item: <ProChecked className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Video Localization Tools",
	// 		tip:
	// 			"Edit and manage multiple subtitle languages tracks at the same time",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	],
	// AI Generated Visuals section
	// [{ type: "border" }],
	// [{ type: "headerTitle", title: "AI Generated Visuals" }],
	// [
	// 	{
	// 		type: "header",
	// 		title: "AI Generated Images",
	// 		tip: "Visuals that are generated by AI",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Up to 5 images/mo" },
	// 	{ type: "title", title: "Up to 200 images/yr" },
	// 	{ type: "title", title: "Up to 500 images/yr" },
	// 	{ type: "title", title: "Up to 800 images/yr" }
	// ],
	// Workflow Tools
	[{ type: "border" }],
	[{ type: "headerTitle", title: "Workflow Tools" }],
	[
		{
			type: "header",
			title: "Screen & Cam Recorder",
			tip: "Record your webcam and screen and add it to the editor",
			infoIcon: <Information />
		},
		{
			type: "icon",
			item: <FreeChecked className={GridListClass} />
		},
		{ type: "icon", item: <BasicChecked className={GridListClass} /> },
		{ type: "icon", item: <ProChecked className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	], [
		{
			type	: "header",
			title: "Video Compressor",
			tip: "Reduce file size for easy distribution",
			infoIcon: <Information />
		},
		{ type: "title", title: "Up to 1GB" },
		{ type: "title", title: "Up to 5GB" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Video Converter",
			tip: "Convert videos into MP4, M4V, WMV, MOV, and AVI file types",
			infoIcon: <Information />
		},
		{ type: "title", title: "Up to 1GB" },
		{ type: "title", title: "Up to 5GB" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Video Player",
			tip: "Let others view your video online instantly",
			infoIcon: <Information />
		},
		{ type: "icon", item: <FreeChecked className={GridListClass} /> },
		{ type: "icon", item: <BasicChecked className={GridListClass} /> },
		{ type: "icon", item: <ProChecked className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Commenting & Review",
	// 		tip: "Collect feedback from you team with timestamped comments",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <FreeChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	], [
		{
			type: "header",
			title: "Share & Embed",
			tip: "Share via URL or embed ad-free video into your website",
			infoIcon: <Information />
		},
		{ type: "icon", item: <FreeChecked className={GridListClass} /> },
		{ type: "icon", item: <BasicChecked className={GridListClass} /> },
		{ type: "icon", item: <ProChecked className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	],
	// Live Streaming section
	// [{ type: "border" }],
	// [{ type: "headerTitle", title: "Live Streaming" }],
	// [
	// 	{
	// 		type: "header",
	// 		title: "Stream Anywhere (1080p)",
	// 		tip: "Youtube, Facebook, LinkedIn, Twitter & Twitch + RTMP",
	// 		infoIcon: <Information />
	// 	},
	// 	{
	// 		type: "icon",
	// 		item: <FreeChecked className={GridListClass} />
	// 	},
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Stream Recording",
	// 		tip: "Your stream gets automatically recorded. Up to 1080p",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Invite Guests",
	// 		tip: "Have up to 8 guests on the stream, including yourself",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Up to 8" },
	// 	{ type: "title", title: "Up to 8" },
	// 	{ type: "title", title: "Up to 8" },
	// 	{ type: "title", title: "Up to 8" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Customizable Stream",
	// 		tip: "Switch between customizable layouts and share your screen",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <FreeChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Transcriptions",
	// 		tip:
	// 			"Automatic transcript of all your broadcasts - support for .txt & .srt",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <FreeChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Content Repurposing",
	// 		tip: "Edit your broadcasts in text-based editor and create clips",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <FreeChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Custom Watermark",
	// 		tip: "Remove the VIDEOO.IO Live logo or replace it with your own",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <FreeChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ],
	// [{ type: "border" }],
	// // Brand Tools
	// [{ type: "headerTitle", title: "Brand Tools" }],
	// [
	// 	{
	// 		type: "header",
	// 		title: "Brand Kit",
	// 		tip: "Easily accessible brand assets to be used across projects",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Custom Fonts",
	// 		tip: "Upload unlimited custom fonts to use with video captions",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Brand Colors",
	// 		tip: "Reusable pallet of your brand colors to use across your projects",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Custom Watermarks",
	// 		tip: "Add custom watermarks on your projects",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ],
	[{ type: "border" }],
	// Corporate Add-Ons
	[{ type: "headerTitle", title: "Corporate Add-Ons" }],
	[
		{
			type: "header",
			title: "Private Workspaces",
			tip: "Segregated private workspaces for multiple teams",
			infoIcon: <Information />
		},
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "link", title: "Contact Sales", link: "mailto:info@videoo.io" }
	], [
		{
			type: "header",
			title: "Integrations",
			tip: "Link up your video production workflow",
			infoIcon: <Information />
		},
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "link", title: "Contact Sales", link: "mailto:info@videoo.io" }
	],
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Batch automation",
	// 		tip: "Auto clean, transcribe, translate and brand videos",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "link", title: "Contact Sales", link: "mailto:info@videoo.io" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Meeting recording",
	// 		tip: "Record, transcribe, share and archive video meetings",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "link", title: "Contact Sales", link: "mailto:info@videoo.io" }
	[
		{
			type: "header",
			title: "Custom privacy & security",
			tip: "Security audits, Data Processing Agreements",
			infoIcon: <Information />
		},
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "link", title: "Contact Sales", link: "mailto:info@videoo.io" }
	]
];

export const FreequentlyQuestionInfo = [
	{
		hader: "Do you provide subscription options for educational institutions?",
		content:
			"We certainly do! Our team offers custom education plans for faculty-wide use. If you're interested in learning more, please reach out to our sales team at ",
		link: { status: true, url: "mailto:info@videoo.io", title: " info@videoo.io" }
	}, {
		hader: "How would the watermark appear?",
		content:
			"The watermark appears in the corner of videos that are produced with the free plan. Our self-funded startup relies on this watermark to expand, but it doesn't take away from the quality of your content. You may opt for one of our EXPERT plans to eliminate the watermark.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "Is there a free trial available for the paid plans?",
		content:
			"If you're interested in trying out our paid plans, we offer a free trial specifically for Corporate customers. To qualify, you'll need a minimum of 6 users. Simply reach out to our sales team to set up your trial via ",
		link: { status: true, url: "mailto:info@videoo.io", title: "info@videoo.io" }
	}, {
		hader: "Is it possible to cancel my subscription at any time?",
		content:
			"Certainly, you can cancel your plan anytime by accessing your account page from your profile. If you decide to cancel, you will still have access to all the premium features until the end of your billing cycle.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "What payment methods and currencies do you accept?",
		content:
			"We accept all major credit and debit cards and prices are displayed in your local currency where possible. If your currency is not available, prices will be displayed in USD. Our payment system is powered by Stripe, a trusted and secure payment processor. If you encounter any issues paying with your card, please don't hesitate to contact us and we'll work with you to find a solution.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "Can I access receipts for my transactions?",
		content: "You can easily access receipts for all your transactions in your account dashboard.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "Is there any change to refund the plan ?",
		content: "If you are wondering about our refund policy, we have detailed it on our Terms of Sale page which can be accessed on our website dashboard.",
		link: { status: true, url: "", title: "" }
	}, {
		hader: "What are the transcription limitations for subtitles and translations?",
		content:
			"Each plan comes with a set limit for transcription minutes during a specific period of time. Both subtitles and translations contribute to your transcription limit. For instance, if you add subtitles to a 30-minute video, it will be counted as 30 minutes towards your limit. Similarly, if you translate it from English to Spanish, it will add another 30 minutes, bringing the total transcription time used to 60 minutes. The limit is set either on a monthly or annual basis, depending on your chosen billing cycle. Any unused minutes during a billing cycle will not carry over to the next cycle. Therefore, you may want to opt for an annual plan for greater flexibility in using your transcription minutes.",
		link: { status: false, url: "", title: "" }
	}, {
		hader:
			"I need a high volume of subtitles and translations over a short time frame, can you help me ?",
		content:
			"If you require a significant amount of subtitles and translations within a short period, and the monthly limit does not meet your needs, you may want to consider upgrading to a higher plan or opting for an annual plan. With an annual plan, you have the flexibility to use your entire yearly transcription limit within a single month if necessary.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "What is the quickest way to get in touch with you if I need assistance?",
		content:
			"We understand that video editing can be challenging, and we value your business. If you need assistance, you can reach us through our email at info@videoo.io or our live chat feature during our business hours, and we will respond promptly.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "What is your Companies House number?",
		content: "Our Companies House number is ",
		link: { status: true, url: "https://beta.companieshouse.gov.uk/company/15462119", title: "15462119" }
	}
];

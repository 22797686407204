import { v4 as uuidv4 } from 'uuid';
import { memo, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ElementsItem } from "./ElementsItem";
import { useWebGL } from '../../videoEdit.provider';
import { TimeLineContext } from "../timeline.config";
import { SERVER_STATUS } from '../../../../axios/ApiProvider';
import { GetElementsWidthLeft } from "../common/commonFunction";
import { SelectedItemID_Store, selectedGroupID_Store } from "../../../../store/actions/webgldata";
import { TimeCount_Store } from '../../../../store/actions/videoPlayAction';

export const ElementsContainer = (props) => {
  const { type } = props;
  const [timelineData, SetTimelineData] = useContext(TimeLineContext);
  const projectJSON = useSelector((store => store.projectdata.projectJSON));
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const imageElements = useSelector(store => store.webgldata.imageElements);
  const thumbNails = useSelector(store => store.projectdata.thumbNails);
  const [gridOptions, setGridOptions] = useState({});
  const gridKeys = Object.keys(gridOptions);

  const [emptyGroupDrag, setEmptyGroupDrag] = useState(false);

  useEffect(() => {
    if (!projectJSON[type]) return;
    const tempOptions = Object.assign({}, projectJSON[type]);
    const tempKeys = Object.keys(tempOptions);

    tempKeys.sort((key1, key2) => (
      tempOptions[key1].sequence - tempOptions[key2].sequence
    ))

    let tempGridData = {};
    tempKeys.forEach(async (optionKey, index) => {
      // if (type === 'videos' && !videoElements[optionKey]) return;
      // if (type === 'images' && !imageElements[optionKey]) return;

      let tempOption = Object.assign({}, tempOptions[optionKey]);
      const [width, left] = await GetElementsWidthLeft(tempOption, timelineData.legendInfo);
      tempOption['id'] = optionKey;
      tempOption['style'] = { width: width, left: left };

      if (type === 'videos') {
        let thumbnails = [];
        let localThumbnails = [];
        const tempAssets = Object.assign({}, projectJSON.asset);
        const asset_uuid = tempOptions[optionKey]['asset_uuid'];

        let asset_info = tempAssets[asset_uuid];
        if (SERVER_STATUS !== 'localhost' && !!asset_info && !!asset_info.thumbnail) {
          if(tempAssets[asset_uuid].localThumbnail) {
            localThumbnails = Object.values(tempAssets[asset_uuid].localThumbnail)?.map(th => th.url)
          }
          thumbnails = tempAssets[asset_uuid].thumbnail;
        }
        else if (!!thumbNails[optionKey]) thumbnails = thumbNails[optionKey];

        let videoAssets = [];
        const thumbKeys = Object.keys(thumbnails);
        let thumbValues = Object.values(thumbnails);
        thumbValues.sort((a, b) => parseFloat(a.timestamp) - parseFloat(b.timestamp));
        // thumbKeys.forEach((thumbKey) => { videoAssets.push(thumbnails[thumbKey].signed_url); });
        thumbValues.forEach((thumbItem) => { videoAssets.push(thumbItem.signed_url); })
        tempOption['thumbnails'] = videoAssets;
        tempOption['localThumbnails'] = localThumbnails;
      }

      if (type === 'images') {
        let thumbnails = {};
        tempOption['thumbnails'] = [];
        const tempAssets = Object.assign({}, projectJSON.asset);
        const asset_uuid = tempOptions[optionKey]['asset_uuid'];

        if (SERVER_STATUS !== 'localhost' && !!asset_uuid && tempAssets[asset_uuid]) {
          thumbnails = tempAssets[asset_uuid];
        } else if (!!thumbNails[asset_uuid]) {
          thumbnails = thumbNails[asset_uuid][0];
        }

        if (!!thumbnails?.signed_url) {
          tempOption['thumbnails'] = [thumbnails.signed_url];
        }
      }


      if (!tempGridData[tempOption.groups]) tempGridData[tempOption.groups] = {};
      tempGridData[tempOption.groups][optionKey] = tempOption;
      if (index + 1 === tempKeys.length) {
        setGridOptions(tempGridData);
      }
    })


    if (!Object.keys(tempGridData).length) setGridOptions({});
  }, [timelineData.legendInfo, projectJSON, videoElements, imageElements]);

  const onDrop = (e) => {
    setEmptyGroupDrag(false);
    const target = e.currentTarget;
    target.style.borderTop = ""
    target.style.backgroundColor = '#fff';
    target.style.height = "3px"
    const dragingGroups = timelineData.dragingGroups;
    if (!dragingGroups || !gridOptions[dragingGroups.id]) return;
    if (dragingGroups.type !== type) return;
    const groupInfo = Object.keys(gridOptions[dragingGroups.id]);
    if (groupInfo.length <= 1) return;

    const newUUID = uuidv4();
    console.log("dragDroping", e);
    let nextUUID = target.getAttribute('next-uuid');
    if (dragingGroups.type === type) {
      console.log("dragDropingddddd", e, newUUID, nextUUID);
      SetTimelineData({ ...timelineData, dropedGroup: { id: newUUID, beforeID: nextUUID } });
    }
  }

  const onDragOver = (e) => {
    setEmptyGroupDrag(true);
    const dragingGroups = timelineData.dragingGroups;
    if (!dragingGroups || !gridOptions[dragingGroups.id]) return;
    if (dragingGroups.type !== type) return;
    const groupInfo = Object.keys(gridOptions[dragingGroups.id]);
    if (groupInfo.length <= 1) return;

    if (!!e.dataTransfer) e.dataTransfer.effectAllowed = 'all';
    if (!!e.dataTransfer) e.dataTransfer.dropEffect = 'move';
    const target = e.currentTarget;
    target.style.height = "60px"
    target.style.borderTop = "3px solid #0098fd"
    target.style.backgroundColor = '#ffeddd';
    e.preventDefault();
  }

  const onDragLeave = (e) => {
    setEmptyGroupDrag(false);
    const dragingGroups = timelineData.dragingGroups;
    if (!dragingGroups || !gridOptions[dragingGroups.id]) return;
    if (dragingGroups.type !== type) return;
    const groupInfo = Object.keys(gridOptions[dragingGroups.id]);
    if (groupInfo.length <= 1) return;

    const target = e.currentTarget;
    target.style.height = "3px"
    target.style.borderTop = ""
    target.style.backgroundColor = '#fff';
    
  }

  
  return (
    gridKeys.map((gridKey, key) =>
      <div className="Elements-groups-wrapper" key={key} group-id={gridKey}>
        <ElementsGroup  gridOptions={gridOptions} groupOptions={gridOptions[gridKey]} groupID={gridKey} type={type} />
        <div className="empty-group-cotainer" next-uuid={gridKey} style={{height: '3px'}}
          onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}/>
      </div>
    )
  )
}

export const ElementsGroup = memo((props) => {
  const elementsGroupConainer = useRef();
  const { gridOptions, groupOptions, groupID, type } = props;
  const groupKeys = Object.keys(groupOptions);
  const projectJSON = useSelector((store => store.projectdata.projectJSON));
  const dispatch = useDispatch();
  const [webGLContext, setWebGLContext] = useWebGL();
  const [timelineData, SetTimelineData] = useContext(TimeLineContext);
  const selectedID = useSelector(store => store.webgldata.selectedItemID);
  const selectedGroupID = useSelector(store => store.webgldata.selectedGroupID);
  const activeGroup = selectedGroupID === groupID
  const Time_Count = useSelector((store) => store.videodata.Time_Count);

  const [isShiftKey, setIsShiftKey] = useState(false);

  const isKeyboardDown = (e) => {
    if(!isShiftKey && e.code == 'ShiftLeft'){
      setIsShiftKey(true);
    }
  }

  const isKeyboardUp = (e) => {
    if(isShiftKey && e.code == 'ShiftLeft'){
      setIsShiftKey(false);
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', isKeyboardDown, false);
    window.addEventListener('keyup', isKeyboardUp, false);
    return () => {
        window.removeEventListener("keydown", isKeyboardDown)
        window.removeEventListener('keyup', isKeyboardUp);
    }
  }, [isShiftKey])

  const onClickItem = (id) => {
    let newUUID = id;
    // if (selectedID === id) return;
    // if(Array.isArray(selectedID) && selectedID.includes(newUUID)){ return;}
    // if(isShiftKey){
    //   let newUUIDArr = [];
    //   if(Array.isArray(selectedID)){
    //     if(selectedID.includes(newUUID)){
    //       newUUIDArr = [];
    //       selectedID.map(idItem => {
    //         if(idItem != newUUID){
    //           newUUIDArr.push(idItem);
    //         }
    //       })
    //     }else{
    //       newUUIDArr = selectedID;
    //       newUUIDArr.push(newUUID);
    //     }
        
    //   }else{
    //     newUUIDArr = [selectedID, newUUID];
    //   }
    //   dispatch(SelectedItemID_Store(newUUIDArr));
      
    // }else{
    //   dispatch(SelectedItemID_Store(newUUID));
    // }
    dispatch(SelectedItemID_Store(newUUID));
    const selectedGElement = groupOptions[newUUID];
    let indicatorT = Time_Count.getTime();
    let nextTime = 0;
    let endTime = selectedGElement?.timeline_end;
    let startTime = selectedGElement?.timeline_start;
    if(indicatorT < endTime && indicatorT > startTime){
    }else{
      if (indicatorT > endTime) nextTime = endTime - 1;
      else if (indicatorT < startTime) nextTime = startTime+1;
      const newTimeData = new Date(nextTime);
      dispatch(TimeCount_Store(newTimeData))
    }
    
    if (!webGLContext.tsManual.state) return;
    let temptsManual = { type: "", state: false, tsID: "", clashID: "", flag: "" };
    setWebGLContext({ ...webGLContext, tsManual: temptsManual });
  }
  const onTouch = () => {
    console.log('constTouch');
  }
  const onDrop = (e) => {
    const dragingGroups = timelineData.dragingGroups;
      console.log('draging', dragingGroups, groupID );
    if (!dragingGroups || dragingGroups.id === groupID) return;
    if (dragingGroups.type !== type) return;
    
    const target = e.currentTarget;
    target.style.backgroundColor = '#fff';
    if (dragingGroups.type === type) {
      SetTimelineData({
        ...timelineData,
        dropedGroup: {
          id: groupID,
          beforeID: groupID
        }
      });
    }
  }

  const onDragOver = (e) => {

    const dragingGroups = timelineData.dragingGroups;
    if (!dragingGroups || dragingGroups.id === groupID) return;
    if (dragingGroups.type !== type) return;

    e.preventDefault();
    if (!!e.dataTransfer) e.dataTransfer.effectAllowed = 'all';
    if (!!e.dataTransfer) e.dataTransfer.dropEffect = 'move';
    const target = e.currentTarget;
    
    target.style.backgroundColor = '#ffeddd';
  }

  const onDragLeave = (e) => {
    const dragingGroups = timelineData.dragingGroups;
    if (!dragingGroups || dragingGroups.id === groupID) return;
    if (dragingGroups.type !== type) return;

    const target = e.currentTarget;
    target.style.backgroundColor = '#fff';
  }
  
  const onClickGroup = (e) => {
    // if(e.target.classList.contains('Element-group-container')){
    //   dispatch(SelectedItemID_Store(''));
    // }
    if (groupID !== selectedGroupID) {
      dispatch(selectedGroupID_Store(groupID))
    }

  }
  const checkSelectedItem = (id) => {
    let isSelectedItem = false;
    if(Array.isArray(selectedID)){
      isSelectedItem = selectedID.includes(id);
    }else{
      isSelectedItem = (selectedID === id);
    }
    return isSelectedItem;
  }
  return (
    <div 
      ref={elementsGroupConainer}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      className={`Element-group-container ${type}-group-wrapper ${activeGroup ? 'bg-bgActive/30' : 'hover:bg-bgActive/5'}`}
      onClick={(e) => {onClickGroup(e)}}
      onTouchMove={() => {}}
      onTouchEndCapture={() =>{} }
    >
      {groupKeys.map((groupKey, key) =>
        <ElementsItem key={key} type={type}
          gridOptions={gridOptions}
          groupData={groupOptions}
          ElementsOb={groupOptions[groupKey]}
          selected={checkSelectedItem(groupOptions[groupKey].id)}
          ClickEven={onClickItem}
          setShiftKey={setIsShiftKey}
        />
      )}
    </div>
  )
})

import { memo, useEffect, useState, useRef } from "react";

export const VideoImages = memo((props) => {
  const timelineContainerItem = useRef();
  const { itemWidth, thumbnails, localThumbnails = [], type, aspect } = props;
  const [style, setStyle] = useState({});
  const [beforeThumb, setBeforeThumb] = useState('');
  const [thumbNailCount, setThumbNailCount] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const [thumbWidth, setThumbWidth] = useState(0)
  
  useEffect(() => {
    
    if (type === 'images') {
      let backImgs = [];
      
      thumbnails.forEach((url, i) => { backImgs.push(`url(${url})`); });
      setStyle({  backgroundColor: 'transparent', backgroundImage: backImgs.join(', ') });
    }
    if (type === 'videos'){
      if(thumbnails[0]){
        makeThumbNailsTimeline();
      }
    }
    setThumbWidth(aspect * 56);
  }, [])
  
  useEffect(() => {
    if(itemWidth != contentWidth ){
      if(itemWidth > 0){
        if(type === "videos"){
          if(thumbnails[0]){
            makeThumbNailsTimeline();
          }
        }
        setContentWidth(itemWidth);
      }else{
        if(thumbnails.length > 0){
          // setStyle({ backgroundImage: `url(${thumbnails[0]})`, backgroundSize: `${thumbWidth}px 56px` });
        }
      }
    }else{
    }
    
  }, [itemWidth])

  useEffect(() => {
    if(itemWidth != contentWidth || thumbNailCount != thumbnails.length || beforeThumb != thumbnails[0]){
      if(type === "videos") {
        if(itemWidth > 0){
          if (thumbnails[0]) {
            makeThumbNailsTimeline();
          }
          setContentWidth(itemWidth);
        }else{
          if(thumbnails.length > 0){
            // setStyle({ backgroundImage: `url(${thumbnails[0]})`, backgroundSize: `${thumbWidth}px 56px` });
          }
        }
      }
    }
  }, [thumbnails, localThumbnails])

  const makeThumbNailsTimeline = () => {
    let calWidthCount = Math.ceil(itemWidth / thumbWidth);
    let thumWidth = aspect * 56;
    let indexArr = [];
    if(thumbnails.length > 1){
      if(calWidthCount > thumbnails.length){
        while (indexArr.length < calWidthCount) {
          indexArr = makethumbArr(indexArr, thumbnails.length, calWidthCount );
        }
      }else{
        for (let index = 0; index < thumbnails.length; index++) {
          indexArr.push(index);
        }
      }
    }else{
      for (let index = 0; index < calWidthCount; index++) {
        indexArr.push(0);
      }
    }
    
    let newthumbs = [];
    indexArr.forEach(id => {
      newthumbs.push(thumbnails[id]);
    });

    let imgs = [];
    let urlText = '';
    let positionText = '';
    newthumbs.map((imgUrl, index) => {
      if(index > 0){
        urlText += `, url(${imgUrl})`;
        positionText += `, top 0px left ${thumWidth * index}px`;
      }else{
        urlText += `url(${imgUrl})`;
        positionText += `top 0px left ${thumWidth * index}px`;
      }
    })
    setStyle({ backgroundImage: urlText, backgroundPosition: positionText, backgroundSize: `${thumWidth}px 56px`, backgroundRepeat: 'no-repeat' });
    setBeforeThumb(thumbnails[0]);
    setThumbNailCount(thumbnails.length);
  }

  const makethumbArr = (newThembArr, arrayCnt, count) => {
    if(newThembArr.length < count){
      if(newThembArr.length > 0){
        for (let index = 0; index < arrayCnt; index++) {
          if(newThembArr.length < count){
            let id = newThembArr.indexOf(index);
            const temArr = [
              ...newThembArr.slice(0, id),
              index,
              ...newThembArr.slice(id)
            ];
            newThembArr = temArr;
          }
        }
      }else{
        for (let index = 0; index < arrayCnt; index++) {
          newThembArr.push(index);
        }
      }
    }
    return newThembArr;
  }

  return (
    <div className={`${type}-thumbnails-container`} ref={timelineContainerItem} style={{ ...style }} />
  )
})